@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/*---------------------------------------------*/
/*--- STYLES NEEDED TO MODIFY ZOOM CONTROLS ---*/
/*---------------------------------------------*/
/* Possible solution: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#getcontainer */

.custom-popup .mapboxgl-popup-content {
  border-radius: 10px; /* Adjust this value to round the corners more */
  padding: 16px;
  width: 300px;
}

/* Position zoom controls in the middle of the right side */
.mapboxgl-ctrl-bottom-right {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  right: 6px;
  bottom: 0; /* Stretch to the bottom */
  z-index: 10;
}

/* Align zoom controls vertically */
.mapboxgl-ctrl-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 32px;
  position: absolute;
  right: 0;
}

/* Ensure the attribution control stays at the bottom of the page */
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
  position: absolute;
  width: max-content;
  bottom: 0px; /* Position it at the bottom of the container with some margin */
  right: 4px; /* Align it to the right */
  z-index: 5; /* Ensure it stays below the zoom controls */
}

.mapboxgl-ctrl-attrib-inner {
  font-size: 10px; /* Make the text smaller */
  padding: 2px 5px; /* Reduce padding */
  line-height: 1.2; /* Adjust line height */
}

.mapboxgl-ctrl-attrib {
  background-color: rgba(255, 255, 255, 0.8); /* Optional: make the background slightly transparent */
  border-radius: 3px; /* Optional: add rounded corners */
}

/* Mobile mapbox controls */
@media (max-width: 640px) { 
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
    /* BEFORE YOU UPDATE BECAUSE LOCAL MOBILE VIEW IS NOT AT BOTTOM */
    /* It should look good on actual mobile devices, please check before editing */
    position: fixed;
    width: max-content;
    bottom: 60px;
    right: 4px; 
  }

  .mapboxgl-ctrl-bottom-left {
    /* BEFORE YOU UPDATE BECAUSE LOCAL MOBILE VIEW IS NOT AT BOTTOM */
    /* It should look good on actual mobile devices, please check before editing */
    display: flex; 
    flex-direction: row; 
    position: fixed !important;
    bottom: 60px !important;
    width: max-content !important;
    left: 4px !important;
    z-index: 50 !important;
  }
}

/* Medium screen mapbox control */
@media (min-width: 640px) and (max-width: 1024px) {
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
    /* BEFORE YOU UPDATE BECAUSE LOCAL MOBILE VIEW IS NOT AT BOTTOM */
    /* It should look good on actual mobile devices, please check before editing */
    position: fixed;
    width: max-content;
    bottom: 60px;
    right: 4px; 
  }

  .mapboxgl-ctrl-bottom-left {
    /* BEFORE YOU UPDATE BECAUSE LOCAL MOBILE VIEW IS NOT AT BOTTOM */
    /* It should look good on actual mobile devices, please check before editing */
    display: flex; 
    flex-direction: row; 
    position: fixed !important;
    bottom: 60px !important;
    width: max-content !important;
    left: 4px !important;
    z-index: 50 !important;
  }
}

.scrollStyle::-webkit-scrollbar {
  width: 20px;
}
.scrollStyle::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollStyle::-webkit-scrollbar-thumb {
  background-color: #d2d8e380;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}